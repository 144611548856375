import {
  Box,
  Container,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Divider,
  Icon,
  Link,
} from "@chakra-ui/react";
import { SiGithub, SiInstagram, SiLinkedin } from "react-icons/si";

const SOCIAL = [
  {
    name: "LinkedIn",
    icon: SiLinkedin,
    link: "https://www.linkedin.com/in/nicolas-peralta/",
  },
  {
    name: "Instagram",
    icon: SiInstagram,
    link: "https://www.instagram.com/nicoperalta88/",
  },
  {
    name: "GitHub",
    icon: SiGithub,
    link: "https://github.com/nico-peralta/",
  },
];

const Footer = () => {
  return (
    <footer>
      <Container maxW="container.lg" mb={10}>
        <Divider my={10} />
        <Grid templateColumns="repeat(4, 1fr)" gap={6} mt={3}>
          <GridItem
            colSpan={3}
            flexDirection={"row"}
            display={"flex"}
            alignItems={"center"}
          >
            <Box
              bg={"purple.500"}
              width={10}
              height={10}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={"lg"}
              cursor="default"
              userSelect={"none"}
              mr={3}
            >
              <Heading color={"white"} fontSize={"xl"}>
                NP
              </Heading>
            </Box>
          </GridItem>
          <GridItem
            flexDirection={"row"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"end"}
          >
            {SOCIAL.map((item) => (
              <IconButton
                as={Link}
                ml={3}
                href={item.link}
                target="_blank"
                key={item.link}
                colorScheme={"purple"}
                variant={"ghost"}
                aria-label={item.name}
                icon={<Icon as={item.icon} />}
              />
            ))}
          </GridItem>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
