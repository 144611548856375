import { useLocation, Link } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  GridItem,
  Heading,
  Button,
  IconButton,
  Icon,
  useColorMode,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { BsFillSunFill, BsFillMoonFill } from "react-icons/bs";
import { MdMenu } from "react-icons/md";

const Navbar = () => {
  const { pathname } = useLocation();
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <nav>
      <Box borderTop={"2px solid"} borderTopColor={"purple.500"} />
      <Container maxW="container.lg" mt={10} mb={20}>
        <Grid templateColumns="repeat(4, 1fr)" gap={6}>
          <GridItem
            colSpan={3}
            flexDirection={"row"}
            display={"flex"}
            alignItems={"center"}
          >
            <Box
              bg={"purple.500"}
              width={10}
              height={10}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={"lg"}
              cursor="default"
              userSelect={"none"}
              mr={3}
            >
              <Heading color={"white"} fontSize={"xl"}>
                NP
              </Heading>
            </Box>
            <Button
              as={Link}
              to="/"
              mx={1}
              isActive={pathname === "/"}
              variant={"ghost"}
              colorScheme={"purple"}
              display={["none", "inline-flex"]}
            >
              Home
            </Button>
            <Button
              as={Link}
              to="/about"
              mx={1}
              isActive={pathname === "/about"}
              variant={"ghost"}
              colorScheme={"purple"}
              display={["none", "inline-flex"]}
            >
              About me
            </Button>
            <Button
              as={Link}
              to="/resume"
              mx={1}
              isActive={pathname === "/resume"}
              variant={"ghost"}
              colorScheme={"purple"}
              display={["none", "inline-flex"]}
            >
              Resume
            </Button>
          </GridItem>
          <GridItem
            flexDirection={"row"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"end"}
          >
            <IconButton
              colorScheme={"purple"}
              variant={"ghost"}
              isActive
              aria-label="Change theme color"
              icon={
                <Icon
                  as={colorMode === "dark" ? BsFillSunFill : BsFillMoonFill}
                />
              }
              onClick={toggleColorMode}
            />
            <Menu>
              <MenuButton
                as={IconButton}
                display={["block", "none"]}
                ml={[1, 0]}
                aria-label="Menu"
                icon={<Icon as={MdMenu} />}
                colorScheme="purple"
                variant="solid"
              />
              <MenuList>
                <MenuItem
                  as={Link}
                  to="/"
                  bgColor={pathname === "/" && "purple.500"}
                  fontWeight="medium"
                  color={pathname === "/" && "white"}
                  variant={"ghost"}
                >
                  Home
                </MenuItem>
                <MenuItem
                  as={Link}
                  to="/about"
                  bgColor={pathname === "/about" && "purple.500"}
                  color={pathname === "/about" && "white"}
                  fontWeight="medium"
                  variant={"ghost"}
                >
                  About me
                </MenuItem>
                <MenuItem
                  as={Link}
                  to="/resume"
                  bgColor={pathname === "/resume" && "purple.500"}
                  color={pathname === "/resume" && "white"}
                  fontWeight="medium"
                  variant={"ghost"}
                >
                  Resume
                </MenuItem>
              </MenuList>
            </Menu>
          </GridItem>
        </Grid>
      </Container>
    </nav>
  );
};

export default Navbar;
