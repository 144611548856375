import { Container, Heading, Link, Text } from "@chakra-ui/react";

const About = () => {
  return (
    <Container maxW={"container.lg"}>
      <Heading mb={3}>About Me</Heading>
      <Text fontSize={"xl"} color={"gray.500"}>
        My name is <Text as={"span"} color={"purple.500"} fontWeight={600}> Nicolas Peralta</Text>, I am from Argentina and I am 26 years old.
        <br />
        <br />
        I am a software developer with experience in both front-end and back-end development. My focus is on staying updated with the latest technologies and methodologies, enabling me to design, develop, and deploy scalable, high-performance applications that meet client needs.
        <br />
        <br />
        Additionally, I have experience in creating infrastructure for applications using tools like Infrastructure as Code (IaC), and I am proficient in both AWS and GCP. I also have experience in CI/CD processes.
        <br />
        <br />
        Throughout my career, I have led complex projects and collaborated closely with teams to ensure the delivery of efficient solutions. I have experience with various JavaScript frameworks and libraries, as well as relational and non-relational databases.
        <br />
        <br />
        In addition to my technical skills, I also have experience in team leadership and collaboration with stakeholders to ensure project success.
        <br />
        <br />
        Currently working at{" "}
        <Text fontWeight={"bold"} color={"#00bf6f"} as={"span"}>
          <Link href="https://www.reflexai.com/" target={"_blank"}>
            ReflexAI
          </Link>
        </Text>
        .
      </Text>
    </Container>
  );
};

export default About;
